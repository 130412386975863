import React from 'react';

function BottomAdd(props) {
    return (
        <div style={{ paddingBottom: "60px" }} className='bottom'>
            <div className='text'>
                Check out my other Elden Ring calculators&nbsp;
                <div className='button' onClick={() => {
                    document.getElementById("menu").style.transform = "translate(-1px, 0px)"
                }}>HERE</div>
            </div>
        </div>
    );
}

export default BottomAdd;