import React, { useEffect, useState } from 'react';
import * as Icon from "react-bootstrap-icons"
const types = require("./types.json")

function Optimised(props) {
    const [optimal, setOptimal] = useState(props.optimal)
    const [limit, setLimit] = useState(1)

    const [type, setType] = useState("")
    const [level, setLevel] = useState(25)
    const [req, setReq] = useState("no")
    const [damType, setDamType] = useState("")

    useEffect(() => {
        setOptimal(props.optimal)
    }, [props.optimal])

    useEffect(() => {
        setLimit(1)
    }, [type, level, req, damType])

    return (
        <div id="weapon-calculator">
            <div className='weapons'>
                <div className='optimal'>
                    <div className='back' onClick={() => {
                        props.setOptimal(null)
                    }}>
                        <div className='arrow'>
                            <Icon.ArrowLeftShort />
                        </div>
                        <div className='text'>
                            BACK
                        </div>
                    </div>

                    <div className='header'>
                        Optimal Weapon
                    </div>

                    <div className='filters'>
                        <div className='title'>
                            Filter optimisation
                        </div>

                        <div style={{ width: "calc(33% - 10px)" }} className='filter'>
                            <div className='label'>
                                Meet Reqs
                            </div>
                            <select style={{ width: "100%" }} onChange={e => {
                                setReq(e.currentTarget.value)
                            }}>
                                <option defaultChecked value="no">No</option>
                                <option defaultChecked value="yes">Yes</option>
                            </select>
                        </div>

                        <div style={{ width: "calc(67% - 10px)" }} className='filter'>
                            <div className='label'>
                                Type
                            </div>
                            <select style={{ width: "100%" }} onChange={e => {
                                if (e.currentTarget.value === "") setType("")
                                else setType(e.currentTarget.value)
                            }}>
                                <option defaultChecked value="">All</option>
                                {types.map(e => {
                                    return <option key={e} value={e}>{e}</option>
                                })}
                            </select>
                        </div>

                        <div style={{ width: "calc(67% - 10px)" }} className='filter'>
                            <div className='label'>
                                Damage Type
                            </div>
                            <select style={{ width: "100%" }} onChange={e => {
                                if (e.currentTarget.value === "") setDamType("")
                                else setDamType(e.currentTarget.value)
                            }}>
                                <option defaultChecked value="">Attack Rating</option>
                                <option value="DPS">DPS</option>
                                <option value="Physical">Physical</option>
                                <option value="Magic">Magic</option>
                                <option value="Fire">Fire</option>
                                <option value="Lightning">Lightning</option>
                                <option value="Holy">Holy</option>
                                <option value="Passive">Passive</option>
                                <option value="Scarlet Rot">Scarlet Rot</option>
                                <option value="Madness">Madness</option>
                                <option value="Sleep">Sleep</option>
                                <option value="Frost">Frost</option>
                                <option value="Poison">Poison</option>
                                <option value="Bleed">Bleed</option>
                            </select>
                        </div>

                        <div style={{ width: "calc(33% - 10px)" }} className='filter'>
                            <div className='label'>
                                Max Level
                            </div>
                            <select style={{ width: "100%" }} onChange={e => {
                                if (e.currentTarget.value === "") setLevel(25)
                                else setLevel(parseInt(e.currentTarget.value))
                            }}>
                                <option defaultChecked value="">All</option>
                                {[" +0", " +1", " +2", " +3", " +4", " +5", " +6", " +7", " +8", " +9", " +10", " +11", " +12", " +13", " +14", " +15", " +16", " +17", " +18", " +19", " +20", " +21", " +22", " +23", " +24", " +25"].map(e => {
                                    return <option key={e} value={e}>{e}</option>
                                })}
                            </select>
                        </div>
                    </div>

                    <div className='results'>
                        {optimal.filter(e => {
                            if (damType === "DPS") return !isNaN(e.ar.dps)
                            else return e
                        }).sort((a, b) => {
                            if (damType === "") return b.ar.total - a.ar.total
                            else if (damType === "DPS") {
                                if (b.ar.dps === a.ar.dps) return b.ar.total - a.ar.total
                                else return b.ar.dps - a.ar.dps
                            }
                            else if (damType === "Passive") {
                                if (b.passive.total === a.passive.total) return b.ar.total - a.ar.total
                                else return b.passive.total - a.passive.total
                            }
                            else if (damType === "Scarlet Rot") {
                                if (b.passive["scarlet rot"] === a.passive["scarlet rot"]) return b.ar.total - a.ar.total
                                else return b.passive["scarlet rot"] - a.passive["scarlet rot"]
                            }
                            else if (damType === "Madness") {
                                if (b.passive.madness === a.passive.madness) return b.ar.total - a.ar.total
                                else return b.passive.madness - a.passive.madness
                            }
                            else if (damType === "Sleep") {
                                if (b.passive.sleep === a.passive.sleep) return b.ar.total - a.ar.total
                                else return b.passive.sleep - a.passive.sleep
                            }
                            else if (damType === "Frost") {
                                if (b.passive.frost === a.passive.frost) return b.ar.total - a.ar.total
                                else return b.passive.frost - a.passive.frost
                            }
                            else if (damType === "Poison") {
                                if (b.passive.poison === a.passive.poison) return b.ar.total - a.ar.total
                                else return b.passive.poison - a.passive.poison
                            }
                            else if (damType === "Bleed") {
                                if (b.passive.blood === a.passive.blood) return b.ar.total - a.ar.total
                                else return b.passive.blood - a.passive.blood
                            }
                            else return b.ar[damType.toLowerCase()] - a.ar[damType.toLowerCase()]
                        }).filter((e, n) => {
                            if (type !== "") {
                                if (e.details.type === type) return e
                                else return undefined
                            } else return e
                        }).filter((e, n) => {
                            if (e.details.level === "") return e
                            else if (level !== 25) {
                                if (level >= parseInt(e.details.level)) return e
                                else return undefined
                            } else return e
                        }).filter((e, n) => {
                            if (req === "yes") {
                                if (
                                    e.required.strength <= props.build.strength
                                    && e.required.dexterity <= props.build.dexterity
                                    && e.required.intelligence <= props.build.intelligence
                                    && e.required.faith <= props.build.faith
                                    && e.required.arcane <= props.build.arcane
                                ) return e
                                else return undefined
                            } else return e
                        }).filter((e, n) => {
                            if (n < limit) return e
                            else return undefined
                        }).length === 0 ? <div className='noresult'>
                            Sorry, no results! Try changing the filters above
                        </div> : null}

                        {optimal.filter(e => {
                            if (damType === "DPS") return !isNaN(e.ar.dps)
                            else return e
                        }).sort((a, b) => {
                            if (damType === "") return b.ar.total - a.ar.total
                            else if (damType === "DPS") {
                                if (b.ar.dps === a.ar.dps) return b.ar.total - a.ar.total
                                else return b.ar.dps - a.ar.dps
                            }
                            else if (damType === "Passive") {
                                if (b.passive.total === a.passive.total) return b.ar.total - a.ar.total
                                else return b.passive.total - a.passive.total
                            }
                            else if (damType === "Scarlet Rot") {
                                if (b.passive["scarlet rot"] === a.passive["scarlet rot"]) return b.ar.total - a.ar.total
                                else return b.passive["scarlet rot"] - a.passive["scarlet rot"]
                            }
                            else if (damType === "Madness") {
                                if (b.passive.madness === a.passive.madness) return b.ar.total - a.ar.total
                                else return b.passive.madness - a.passive.madness
                            }
                            else if (damType === "Sleep") {
                                if (b.passive.sleep === a.passive.sleep) return b.ar.total - a.ar.total
                                else return b.passive.sleep - a.passive.sleep
                            }
                            else if (damType === "Frost") {
                                if (b.passive.frost === a.passive.frost) return b.ar.total - a.ar.total
                                else return b.passive.frost - a.passive.frost
                            }
                            else if (damType === "Poison") {
                                if (b.passive.poison === a.passive.poison) return b.ar.total - a.ar.total
                                else return b.passive.poison - a.passive.poison
                            }
                            else if (damType === "Bleed") {
                                if (b.passive.blood === a.passive.blood) return b.ar.total - a.ar.total
                                else return b.passive.blood - a.passive.blood
                            }
                            else return b.ar[damType.toLowerCase()] - a.ar[damType.toLowerCase()]
                        }).filter((e, n) => {
                            if (type !== "") {
                                if (e.details.type === type) return e
                                else return undefined
                            } else return e
                        }).filter((e, n) => {
                            if (e.details.level === "") return e
                            else if (level !== 25) {
                                if (level >= parseInt(e.details.level)) return e
                                else return undefined
                            } else return e
                        }).filter((e, n) => {
                            if (req === "yes") {
                                if (
                                    e.required.strength <= props.build.strength
                                    && e.required.dexterity <= props.build.dexterity
                                    && e.required.intelligence <= props.build.intelligence
                                    && e.required.faith <= props.build.faith
                                    && e.required.arcane <= props.build.arcane
                                ) return e
                                else return undefined
                            } else return e
                        }).filter((e, n) => {
                            if (n < limit) return e
                            else return undefined
                        }).map((e, n) => {
                            return <div key={n}>
                                {n > 0 ? <hr /> : null}
                                <div className='optimal_weapon_item'>
                                    <div className='overview'>
                                        <div className='name'>
                                            {n + 1}. {e.details.name} {e.details.level}
                                        </div>
                                        <div className='weapon_type'>
                                            {e.details.type}
                                        </div>
                                        {/* <div className='attack_rating'>
                                            Attack Rating
                                            <div className="value">{e.ar.total}</div>
                                        </div>
                                        {e.ar.dps > 0 ?
                                            <div className='attack_rating' style={{ marginTop: "5px" }}>
                                                DPS
                                                <div className="value">{e.ar.dps}</div>
                                            </div>
                                            : null} */}
                                    </div>
                                    <div className='overview'>
                                        <div className='requirements' >
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <td></td>
                                                        <td style={{ borderTopLeftRadius: "3px", textAlign: "center" }}>STR</td>
                                                        <td style={{ textAlign: "center" }}>DEX</td>
                                                        <td style={{ textAlign: "center" }}>INT</td>
                                                        <td style={{ textAlign: "center" }}>FTH</td>
                                                        <td style={{ borderTopRightRadius: "3px", textAlign: "center" }}>ARC</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ borderTopLeftRadius: "3px" }}>Requirements</td>
                                                        <td style={{ color: e.required.strength > props.build.strength ? "red" : "" }}>{e.required.strength}</td>
                                                        <td style={{ color: e.required.dexterity > props.build.dexterity ? "red" : "" }}>{e.required.dexterity}</td>
                                                        <td style={{ color: e.required.intelligence > props.build.intelligence ? "red" : "" }}>{e.required.intelligence}</td>
                                                        <td style={{ color: e.required.faith > props.build.faith ? "red" : "" }}>{e.required.faith}</td>
                                                        <td style={{ color: e.required.arcane > props.build.arcane ? "red" : "" }}>{e.required.arcane}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ borderBottomLeftRadius: "3px" }}>Scaling</td>
                                                        <td>{e.letters.strength.letter} {e.letters.strength.letter === "-" ? null : `(${parseFloat(e.letters.strength.value).toFixed(1)})`}</td>
                                                        <td>{e.letters.dexterity.letter} {e.letters.dexterity.letter === "-" ? null : `(${parseFloat(e.letters.dexterity.value).toFixed(1)})`}</td>
                                                        <td>{e.letters.intelligence.letter} {e.letters.intelligence.letter === "-" ? null : `(${parseFloat(e.letters.intelligence.value).toFixed(1)})`}</td>
                                                        <td>{e.letters.faith.letter} {e.letters.faith.letter === "-" ? null : `(${parseFloat(e.letters.faith.value).toFixed(1)})`}</td>
                                                        <td style={{ borderBottomRightRadius: "3px" }}>{e.letters.arcane.letter} {e.letters.arcane.letter === "-" ? null : `(${parseFloat(e.letters.arcane.value).toFixed(1)})`}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='column'>
                                        <div className='damage'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <td></td>
                                                        <td style={{ textAlign: "center", fontSize: "20px", padding: "5px 5px 5px 20px" }}>Passive</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getPassiveTr(e, "Scarlet Rot")}
                                                    {getPassiveTr(e, "Madness")}
                                                    {getPassiveTr(e, "Sleep")}
                                                    {getPassiveTr(e, "Frost")}
                                                    {getPassiveTr(e, "Poison")}
                                                    {getPassiveTr(e, "Blood")}
                                                    <tr>
                                                        <td style={{ border: "none", textAlign: "right", paddingRight: "0px", paddingTop: "10px" }} colSpan={1}>Total Passive</td>
                                                        <td><div className='ar'>{e.passive.total}</div></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='column'>
                                        {getDamageTable(e)}
                                    </div>
                                </div>
                            </div>
                        })}

                        {limit <= optimal.filter(e => {
                            if (damType === "DPS") return !isNaN(e.ar.dps)
                            else return e
                        }).sort((a, b) => {
                            if (damType === "") return b.ar.total - a.ar.total
                            else if (damType === "DPS") {
                                if (b.ar.dps === a.ar.dps) return b.ar.total - a.ar.total
                                else return b.ar.dps - a.ar.dps
                            }
                            else if (damType === "Passive") {
                                if (b.passive.total === a.passive.total) return b.ar.total - a.ar.total
                                else return b.passive.total - a.passive.total
                            }
                            else if (damType === "Scarlet Rot") {
                                if (b.passive["scarlet rot"] === a.passive["scarlet rot"]) return b.ar.total - a.ar.total
                                else return b.passive["scarlet rot"] - a.passive["scarlet rot"]
                            }
                            else if (damType === "Madness") {
                                if (b.passive.madness === a.passive.madness) return b.ar.total - a.ar.total
                                else return b.passive.madness - a.passive.madness
                            }
                            else if (damType === "Sleep") {
                                if (b.passive.sleep === a.passive.sleep) return b.ar.total - a.ar.total
                                else return b.passive.sleep - a.passive.sleep
                            }
                            else if (damType === "Frost") {
                                if (b.passive.frost === a.passive.frost) return b.ar.total - a.ar.total
                                else return b.passive.frost - a.passive.frost
                            }
                            else if (damType === "Poison") {
                                if (b.passive.poison === a.passive.poison) return b.ar.total - a.ar.total
                                else return b.passive.poison - a.passive.poison
                            }
                            else if (damType === "Bleed") {
                                if (b.passive.blood === a.passive.blood) return b.ar.total - a.ar.total
                                else return b.passive.blood - a.passive.blood
                            }
                            else return b.ar[damType.toLowerCase()] - a.ar[damType.toLowerCase()]
                        }).filter((e, n) => {
                            if (type !== "") {
                                if (e.details.type === type) return e
                                else return undefined
                            } else return e
                        }).filter((e, n) => {
                            if (e.details.level === "") return e
                            else if (level !== 25) {
                                if (level >= parseInt(e.details.level)) return e
                                else return undefined
                            } else return e
                        }).filter((e, n) => {
                            if (req === "yes") {
                                if (
                                    e.required.strength <= props.build.strength
                                    && e.required.dexterity <= props.build.dexterity
                                    && e.required.intelligence <= props.build.intelligence
                                    && e.required.faith <= props.build.faith
                                    && e.required.arcane <= props.build.arcane
                                ) return e
                                else return undefined
                            } else return e
                        }).filter((e, n) => {
                            if (n < limit) return e
                            else return undefined
                        }).length ? <div className='loadmore' onClick={() => {
                            if (limit === 1) setLimit(limit + 4)
                            else setLimit(limit + 5)
                        }}>
                            Load more
                        </div> : null}
                    </div>
                </div>
            </div>
        </div >
    );

    function getPassiveTr(e, att) {
        return <tr>
            <td>{att === "Blood" ? "Bleed" : att}</td>
            <td><div className='dmg' style={{ margin: "auto" }}>{e.passive[att.toLowerCase()]}</div></td>
        </tr>
    }

    function getDamageTable(e) {
        return <div className='damage'>
            <table>
                <thead>
                    <tr>
                        <td></td>
                        <td style={{ textAlign: "left", fontSize: "20px", padding: "5px 5px 5px 20px" }} colSpan={2}>Damage</td>
                        <td style={{ textAlign: "left", fontSize: "20px", padding: "5px 5px 5px 20px" }}>Total</td>
                    </tr>
                </thead>
                <tbody>
                    {getResultTr("Physical", e)}
                    {getResultTr("Magic", e)}
                    {getResultTr("Fire", e)}
                    {getResultTr("Lightning", e)}
                    {getResultTr("Holy", e)}
                    <tr>
                        <td style={{ border: "none", textAlign: "right", paddingRight: "0px", paddingTop: "10px" }} colSpan={3}><b>Attack Rating</b></td>
                        <td><div className='ar'>{e.ar.total}</div></td>
                    </tr>
                    {e.ar.dps > 0 ?
                        <tr>
                            <td style={{ border: "none", textAlign: "right", paddingRight: "0px", paddingTop: "10px" }} colSpan={3}><b>DPS</b></td>
                            <td><div className='ar'>{e.ar.dps}</div></td>
                        </tr>
                        : null}
                </tbody>
            </table>
        </div>
    }

    function getResultTr(att, e) {
        return <tr>
            <td>{att}</td>
            <td><div className='dmg'>{e.base[att.toLowerCase()]}</div></td>
            <td style={{ color: e.scaling[att.toLowerCase()] < 0 ? "red" : e.scaling[att.toLowerCase()] > 0 ? "green" : "" }}>{e.scaling[att.toLowerCase()] >= 0 ? `+${e.scaling[att.toLowerCase()]}` : e.scaling[att.toLowerCase()]}</td>
            <td><b>{e.ar[att.toLowerCase()]}</b></td>
        </tr>
    }
}

export default Optimised;