import commaNumber from 'comma-number';
import eldenring from 'elden-ring';
import React, { useEffect, useState } from 'react';
import Accordion from '../Accordion/Accordion';
import AccordionButton from '../Accordion/AccordionButton';
import hp from "./hp.json"
import fp from "./fp.json"
import stamina from "./stamina.json"
import load from "./load.json"
import * as Icon from "react-bootstrap-icons"

function Optimised(props) {
    const [build, setBuild] = useState(props.build)

    useEffect(() => {
        setBuild(props.build)
    }, [props.build])

    const [comparable, setComparable] = useState({})
    const [top, setTop] = useState([])
    const [override, setOverride] = useState("")

    const classes = {
        Hero: {
            runes: 0,
            level: 7,
            vigor: 14,
            mind: 9,
            endurance: 12,
            strength: 16,
            dexterity: 9,
            intelligence: 7,
            faith: 8,
            arcane: 11
        },
        Bandit: {
            runes: 0,
            level: 5,
            vigor: 10,
            mind: 11,
            endurance: 10,
            strength: 9,
            dexterity: 13,
            intelligence: 9,
            faith: 8,
            arcane: 14
        },
        Astrologer: {
            runes: 0,
            level: 6,
            vigor: 9,
            mind: 15,
            endurance: 9,
            strength: 8,
            dexterity: 12,
            intelligence: 16,
            faith: 7,
            arcane: 9
        },
        Warrior: {
            runes: 0,
            level: 8,
            vigor: 11,
            mind: 12,
            endurance: 11,
            strength: 10,
            dexterity: 16,
            intelligence: 10,
            faith: 8,
            arcane: 9
        },
        Prisoner: {
            runes: 0,
            level: 9,
            vigor: 11,
            mind: 12,
            endurance: 11,
            strength: 11,
            dexterity: 14,
            intelligence: 14,
            faith: 6,
            arcane: 9
        },
        Confessor: {
            runes: 0,
            level: 10,
            vigor: 10,
            mind: 13,
            endurance: 10,
            strength: 12,
            dexterity: 12,
            intelligence: 9,
            faith: 14,
            arcane: 9
        },
        Wretch: {
            runes: 0,
            level: 1,
            vigor: 10,
            mind: 10,
            endurance: 10,
            strength: 10,
            dexterity: 10,
            intelligence: 10,
            faith: 10,
            arcane: 10
        },
        Vagabond: {
            runes: 0,
            level: 9,
            vigor: 15,
            mind: 10,
            endurance: 11,
            strength: 14,
            dexterity: 13,
            intelligence: 9,
            faith: 9,
            arcane: 7
        },
        Prophet: {
            runes: 0,
            level: 7,
            vigor: 10,
            mind: 14,
            endurance: 8,
            strength: 11,
            dexterity: 10,
            intelligence: 7,
            faith: 16,
            arcane: 10
        },
        Samurai: {
            runes: 0,
            level: 9,
            vigor: 12,
            mind: 11,
            endurance: 13,
            strength: 12,
            dexterity: 15,
            intelligence: 9,
            faith: 8,
            arcane: 8
        }
    }

    useEffect(() => {
        compareClasses()
    }, [build])

    function compareClasses() {
        let obj = {}
        Object.entries(classes).map(e => {
            let option = e[1]

            let sections = Object.entries(build).map(e => {
                return e[0]
            })

            for (let i = 0; i < sections.length; i++) {

                if ((build[sections[i]] - e[1][sections[i]]) > 0) {
                    let level = option.level + (build[sections[i]] - e[1][sections[i]])
                    option = {
                        ...option,
                        runes: typeof eldenring.runesFromTo(classes[e[0]].level, level) === "number" ? eldenring.runesFromTo(classes[e[0]].level, level) : 0,
                        level,
                        [sections[i]]: build[sections[i]]
                    }
                }
            }

            obj[e[0]] = option
        })
        setComparable(obj)
    }

    useEffect(() => {
        if (comparable !== {}) {
            let sorted = Object.entries(comparable).sort((a, b) => {
                let a_test = eldenring.runesFromTo(classes[a[0]].level, a[1].level)
                if (typeof a_test !== "number") a_test = 0
                let b_test = eldenring.runesFromTo(classes[b[0]].level, b[1].level)
                if (typeof b_test !== "number") b_test = 0
                if (a_test < b_test) return -1
                if (a_test > b_test) return 1
                else return 0
            })
            setTop(sorted)
            if (sorted.length > 0) {
                if (sorted[0][0] === override) setOverride("")
            }
        }
    }, [comparable])

    return (
        <div id="build-calculator">
            <div className='optimised'>
                <div className='title'>
                    What's the best class for your build?
                </div>

                <div className='sub-title'>
                    Enter your desired build and we'll tell you the cheapest class to start with!
                </div>

                <div className='levels'>
                    {getLevel("Vigor", "vigor", 9)}
                    {getLevel("Mind", "mind", 9)}
                    {getLevel("Endurance", "endurance", 8)}
                    {getLevel("Strength", "strength", 8)}
                    {getLevel("Dexterity", "dexterity", 8)}
                    {getLevel("Intelligence", "intelligence", 7)}
                    {getLevel("Faith", "faith", 6)}
                    {getLevel("Arcane", "arcane", 7)}
                </div>

                <div className='preresult'>Based on your build...</div>
                <div className='result'>
                    ...you should be a <b>{top.length > 0 ? top[0][0] : null}</b>!

                    <div className='stats'>
                        <div style={{ marginBottom: "10px" }}>{top.length > 0 ? top[0][0] : null} Statistics</div>
                        <div className='column'>
                            <div className='stat'>
                                <div className='label'>
                                    Level
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? top[0][1].level : 0}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label'>
                                    Total runes
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? commaNumber(top[0][1].runes) : 0}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label'>
                                    Runes to next level
                                </div>
                                <div className='value'>
                                    {/* {top.length > 0 ? top[0][1].level : null} */}
                                    {top.length > 0 ? commaNumber(eldenring.nextLevel(top[0][1].level).runes) : null}
                                </div>
                            </div>
                        </div>

                        <div className='column'>
                            <div className='stat'>
                                <div className='label'>
                                    HP
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? hp[top[0][1].vigor] : null}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label'>
                                    FP
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? fp[top[0][1].mind] : null}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label'>
                                    Stamina
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? stamina[top[0][1].endurance] : null}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label'>
                                    Equip load
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? load[top[0][1].endurance] : null}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label' style={{ fontSize: "12px", width: "100%" }}>
                                    (
                                    Fast roll: <b>{top.length > 0 ? (load[top[0][1].endurance] * 0.299).toFixed(1) : null}</b>,
                                    Normal roll: <b>{top.length > 0 ? (load[top[0][1].endurance] * 0.699).toFixed(1) : null}</b>
                                    )
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='compare'>
                        <div className='text'>
                            Select another class to compare
                        </div>
                        <div className='buttons'>
                            {Object.keys(classes).map(e => {
                                if (top.length > 0) {
                                    if (top[0][0] !== e) return <div onClick={() => setOverride(e)} className={override === e ? "compare_button active" : "compare_button"}>{e}</div>
                                }
                            })}
                        </div>
                        {/* <select value={override} onChange={e => setOverride(e.currentTarget.value)}>
                        <option selected disabled value="">Another class</option>
                        <option style={{display: top.length > 0 && top[0][0] === "Hero" ? "none" : ""}} value="Hero">Hero</option>
                        <option style={{display: top.length > 0 && top[0][0] === "Bandit" ? "none" : ""}} value="Bandit">Bandit</option>
                        <option style={{display: top.length > 0 && top[0][0] === "Astrologer" ? "none" : ""}} value="Astrologer">Astrologer</option>
                        <option style={{display: top.length > 0 && top[0][0] === "Warrior" ? "none" : ""}} value="Warrior">Warrior</option>
                        <option style={{display: top.length > 0 && top[0][0] === "Prisoner" ? "none" : ""}} value="Prisoner">Prisoner</option>
                        <option style={{display: top.length > 0 && top[0][0] === "Confessor" ? "none" : ""}} value="Confessor">Confessor</option>
                        <option style={{display: top.length > 0 && top[0][0] === "Wretch" ? "none" : ""}} value="Wretch">Wretch</option>
                        <option style={{display: top.length > 0 && top[0][0] === "Vagabond" ? "none" : ""}} value="Vagabond">Vagabond</option>
                        <option style={{display: top.length > 0 && top[0][0] === "Prophet" ? "none" : ""}} value="Prophet">Prophet</option>
                        <option style={{display: top.length > 0 && top[0][0] === "Samurai" ? "none" : ""}} value="Samurai">Samurai</option>
                    </select> */}
                    </div>

                    {override ? <div className='stats'>
                        <div style={{ marginBottom: "10px" }}>{override} Statistics</div>
                        <div className='column'>
                            <div className='stat'>
                                <div className='label'>
                                    Level
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? top.filter(e => e[0] === override)[0][1].level : 0}
                                </div>
                                <div style={{ color: top.length > 0 && top.filter(e => e[0] === override)[0][1].level - top[0][1].level > 0 ? "green" : top.filter(e => e[0] === override)[0][1].level - top[0][1].level < 0 ? "red" : "" }} className='comparison'>
                                    {top.length > 0 && top.filter(e => e[0] === override)[0][1].level - top[0][1].level >= 0 ? "+" : ""}{top.length > 0 ? top.filter(e => e[0] === override)[0][1].level - top[0][1].level : 0}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label'>
                                    Total runes
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? commaNumber(top.filter(e => e[0] === override)[0][1].runes) : 0}
                                </div>
                                <div style={{ color: top.length > 0 && top.filter(e => e[0] === override)[0][1].runes - top[0][1].runes < 0 ? "green" : top.filter(e => e[0] === override)[0][1].runes - top[0][1].runes > 0 ? "red" : "" }} className='comparison'>
                                    {top.length > 0 && top.filter(e => e[0] === override)[0][1].runes - top[0][1].runes >= 0 ? "+" : ""}{top.length > 0 ? commaNumber(top.filter(e => e[0] === override)[0][1].runes - top[0][1].runes) : 0}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label'>
                                    Runes to next level
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? commaNumber(eldenring.nextLevel(top.filter(e => e[0] === override)[0][1].level).runes) : null}
                                </div>
                                <div style={{ color: top.length > 0 && eldenring.nextLevel(top.filter(e => e[0] === override)[0][1].level).runes - eldenring.nextLevel(top[0][1].level).runes < 0 ? "green" : eldenring.nextLevel(top.filter(e => e[0] === override)[0][1].level).runes - eldenring.nextLevel(top[0][1].level).runes > 0 ? "red" : "" }} className='comparison'>
                                    {top.length > 0 && eldenring.nextLevel(top.filter(e => e[0] === override)[0][1].level).runes - eldenring.nextLevel(top[0][1].level).runes >= 0 ? "+" : ""}{top.length > 0 ? commaNumber(eldenring.nextLevel(top.filter(e => e[0] === override)[0][1].level).runes - eldenring.nextLevel(top[0][1].level).runes) : 0}
                                </div>
                            </div>
                        </div>

                        <div className='column'>
                            <div className='stat'>
                                <div className='label'>
                                    HP
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? hp[top.filter(e => e[0] === override)[0][1].vigor] : null}
                                </div>
                                <div style={{ color: top.length > 0 && hp[top.filter(e => e[0] === override)[0][1].vigor] - hp[top[0][1].vigor] > 0 ? "green" : hp[top.filter(e => e[0] === override)[0][1].vigor] - hp[top[0][1].vigor] < 0 ? "red" : "" }} className='comparison'>
                                    {top.length > 0 && hp[top.filter(e => e[0] === override)[0][1].vigor] - hp[top[0][1].vigor] >= 0 ? "+" : ""}{top.length > 0 ? hp[top.filter(e => e[0] === override)[0][1].vigor] - hp[top[0][1].vigor] : 0}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label'>
                                    FP
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? fp[top.filter(e => e[0] === override)[0][1].mind] : null}
                                </div>
                                <div style={{ color: top.length > 0 && fp[top.filter(e => e[0] === override)[0][1].mind] - fp[top[0][1].mind] > 0 ? "green" : fp[top.filter(e => e[0] === override)[0][1].mind] - fp[top[0][1].mind] < 0 ? "red" : "" }} className='comparison'>
                                    {top.length > 0 && fp[top.filter(e => e[0] === override)[0][1].mind] - fp[top[0][1].mind] >= 0 ? "+" : ""}{top.length > 0 ? fp[top.filter(e => e[0] === override)[0][1].mind] - fp[top[0][1].mind] : 0}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label'>
                                    Stamina
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? stamina[top.filter(e => e[0] === override)[0][1].endurance] : null}
                                </div>
                                <div style={{ color: top.length > 0 && stamina[top.filter(e => e[0] === override)[0][1].endurance] - stamina[top[0][1].endurance] > 0 ? "green" : stamina[top.filter(e => e[0] === override)[0][1].endurance] - stamina[top[0][1].endurance] < 0 ? "red" : "" }} className='comparison'>
                                    {top.length > 0 && stamina[top.filter(e => e[0] === override)[0][1].endurance] - stamina[top[0][1].endurance] >= 0 ? "+" : ""}{top.length > 0 ? stamina[top.filter(e => e[0] === override)[0][1].endurance] - stamina[top[0][1].endurance] : 0}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label'>
                                    Equip load
                                </div>
                                <div className='value'>
                                    {top.length > 0 ? load[top.filter(e => e[0] === override)[0][1].endurance] : null}
                                </div>
                                <div style={{ color: top.length > 0 && load[top.filter(e => e[0] === override)[0][1].endurance] - load[top[0][1].endurance] > 0 ? "green" : load[top.filter(e => e[0] === override)[0][1].endurance] - load[top[0][1].endurance] < 0 ? "red" : "" }} className='comparison'>
                                    {top.length > 0 && load[top.filter(e => e[0] === override)[0][1].endurance] - load[top[0][1].endurance] >= 0 ? "+" : ""}{top.length > 0 ? parseFloat(load[top.filter(e => e[0] === override)[0][1].endurance] - load[top[0][1].endurance]).toFixed(1) : 0}
                                </div>
                            </div>
                            <div className='stat'>
                                <div className='label' style={{ fontSize: "12px", width: "100%" }}>
                                    (
                                    Fast roll:
                                    <b>{top.length > 0 ? (load[top.filter(e => e[0] === override)[0][1].endurance] * 0.299).toFixed(1) : null}</b>
                                    &nbsp;{top.length > 0 ? <div style={{ display: "inline-block", color: ((load[top.filter(e => e[0] === override)[0][1].endurance] * 0.299).toFixed(1) - load[top[0][1].endurance] * 0.299).toFixed(1) >= 0 ? "green" : "red" }}>
                                        {((load[top.filter(e => e[0] === override)[0][1].endurance] * 0.299).toFixed(1) - load[top[0][1].endurance] * 0.299).toFixed(1) >= 0 ? "+" : ""}{((load[top.filter(e => e[0] === override)[0][1].endurance] * 0.299).toFixed(1) - load[top[0][1].endurance] * 0.299).toFixed(1)}
                                    </div> : null}

                                    ,
                                    Normal roll: <b>{top.length > 0 ? (load[top.filter(e => e[0] === override)[0][1].endurance] * 0.699).toFixed(1) : null}</b>
                                    &nbsp;{top.length > 0 ? <div style={{ display: "inline-block", color: ((load[top.filter(e => e[0] === override)[0][1].endurance] * 0.699).toFixed(1) - load[top[0][1].endurance] * 0.699).toFixed(1) >= 0 ? "green" : "red" }}>
                                        {((load[top.filter(e => e[0] === override)[0][1].endurance] * 0.699).toFixed(1) - load[top[0][1].endurance] * 0.699).toFixed(1) >= 0 ? "+" : ""}{((load[top.filter(e => e[0] === override)[0][1].endurance] * 0.699).toFixed(1) - load[top[0][1].endurance] * 0.699).toFixed(1)}
                                    </div> : null}
                                    )
                                </div>
                            </div>
                        </div>
                    </div> : null}
                </div>

                <div className="accordion">
                    <AccordionButton id="optimisation_acc" phrase="View breakdown" alt="Hide breakdown" chevron />
                    <Accordion id="optimisation_acc">
                        <div className='table_container'>
                            <table>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td style={{ textAlign: "left" }}>Class</td>
                                        <td>Runes</td>
                                        <td>Level</td>
                                        <td style={{ padding: "0px 5px" }}>VIG</td>
                                        <td style={{ padding: "0px 5px" }}>MND</td>
                                        <td style={{ padding: "0px 5px" }}>END</td>
                                        <td style={{ padding: "0px 5px" }}>STR</td>
                                        <td style={{ padding: "0px 5px" }}>DEX</td>
                                        <td style={{ padding: "0px 5px" }}>INT</td>
                                        <td style={{ padding: "0px 5px" }}>FTH</td>
                                        <td style={{ padding: "0px 5px" }}>ARC</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {top.map((e, n) => {
                                        return <tr>
                                            <td><b>{n + 1}.</b></td>
                                            <td style={{ textAlign: "left" }}>{e[0]}</td>
                                            <td style={{ textUnderlineOffset: "2px", textDecoration: "underline", textDecorationStyle: "dotted" }}><b>{commaNumber(e[1].runes)}</b></td>
                                            <td>{e[1].level}</td>
                                            <td>{e[1].vigor}</td>
                                            <td>{e[1].mind}</td>
                                            <td>{e[1].endurance}</td>
                                            <td>{e[1].strength}</td>
                                            <td>{e[1].dexterity}</td>
                                            <td>{e[1].intelligence}</td>
                                            <td>{e[1].faith}</td>
                                            <td>{e[1].arcane}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Accordion>
                </div>
            </div>
        </div>
    );

    function getLevel(title, variable, min) {
        return <div className='level'>
            <div className='title'>
                {title}
            </div>
            <input className='level_value' type="number" value={build[variable]} onChange={e => {
                props.setBuild({
                    ...build,
                    [variable]: parseInt(e.currentTarget.value)
                })
            }} />
            {/* <div className='level_value'>{build[variable]}</div> */}
            <div onClick={() => {
                let value = build[variable] - 1
                if (value < min) value = min
                props.setBuild({
                    ...build,
                    [variable]: value
                })
            }} style={{ display: "inline-block", fontSize: "22px", verticalAlign: "top", marginBottom: "-7px", marginRight: "7px", cursor: "pointer" }}><Icon.Dash /></div>
            <div className='slider_container'>
                <input type="range" min={min} max="99" value={build[variable]} className="slider" id="myRange" onChange={e => {
                    props.setBuild({
                        ...build,
                        [variable]: parseInt(e.currentTarget.value)
                    })
                }} />
                <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", marginTop: "0px", marginBottom: "-20px", fontWeight: "600" }}>
                    <div className="slider_label" style={{ color: "#002b36", fontSize: "12px" }}>
                        {min}
                    </div>
                    <div className="slider_label" style={{ color: "#002b36", fontSize: "12px" }}>
                        99
                    </div>
                </div>
            </div>
            <div onClick={() => {
                let value = build[variable] + 1
                if (value > 99) value = 99
                props.setBuild({
                    ...build,
                    [variable]: value
                })
            }} style={{ display: "inline-block", fontSize: "22px", verticalAlign: "top", marginBottom: "-7px", marginLeft: "7px", cursor: "pointer" }}><Icon.Plus /></div>
        </div>
    }
}

export default Optimised;