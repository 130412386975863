import React, { useEffect, useState } from 'react';
import * as Icon from "react-bootstrap-icons"
import getWeaponBreakdown from './calculations';
import Optimised from './optimised';
import Weapon from './weapon';
const affinities = require("./affinities.json")
const weapon_types = require("./weapon_types.json")

function Weapons(props) {
    const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const is_android = navigator.userAgent.toLowerCase().indexOf("android") > -1;
    const is_iphone = navigator.userAgent.toLowerCase().indexOf("iphone") > -1
    const is_phone = is_android || is_iphone

    const [build, setBuild] = useState(props.build)

    const [str, setStr] = useState(props.build.strength)
    const [dex, setDex] = useState(props.build.dexterity)
    const [int, setInt] = useState(props.build.intelligence)
    const [fth, setFth] = useState(props.build.faith)
    const [arc, setArc] = useState(props.build.arcane)

    useEffect(() => {
        setBuild(props.build)
        if (twoHanding) setStr(Math.floor(props.build.strength * 1.5))
        else setStr(props.build.strength)
        setTwoHandingValue(Math.floor(props.build.strength * 1.5))
        setDex(props.build.dexterity)
        setInt(props.build.intelligence)
        setFth(props.build.faith)
        setArc(props.build.arcane)
    }, [props.build])

    const [baseWeapon, setBaseWeapon] = useState("Dagger")
    const [affinity, setAffinity] = useState(localStorage.getItem("eldenring-affinity") || "")
    const [weapon, setWeapon] = useState(affinity ? `${affinity} ${baseWeapon}` : baseWeapon)

    const [twoHanding, setTwoHanding] = useState(false)
    const [twoHandingValue, setTwoHandingValue] = useState(Math.floor(props.build.strength * 1.5))

    const [add, setAdd] = useState("")

    const [result, setResult] = useState({})

    const [optimal, setOptimal] = useState(null)

    const [loading, setLoading] = useState(false)

    const [search, setSearch] = useState(false)

    const [fullScreen, setFullScreen] = useState(false)
    const [fullWidth, setFullWidth] = useState(document.body.clientWidth)

    const [compare, setCompare] = useState([])

    useEffect(() => {
        if (twoHanding) setStr(twoHandingValue)
        else if (twoHanding === false) setStr(props.build.strength)
    }, [twoHanding])

    useEffect(() => {
        if (!affinities[baseWeapon].includes(affinity)) {
            setAffinity("None")
            setWeapon(baseWeapon)
        } else {
            if (affinity !== "None") {
                setWeapon(affinity ? `${affinity} ${baseWeapon}` : baseWeapon)
            } else {
                setWeapon(baseWeapon)
            }
        }
    }, [baseWeapon, affinity])

    function storeWeapon(aff, bas, ad, th) {
        try {
            if (aff) {
                if (aff === "None") localStorage.removeItem("eldenring-affinity")
                else localStorage.setItem("eldenring-affinity", aff)
            }
            if (bas) localStorage.setItem("eldenring-baseweapon", bas)
            if (ad || ad === "") localStorage.setItem("eldenring-add", ad)
            localStorage.setItem("eldenring-twohanding", th)
        } catch (err) { }
    }

    useEffect(() => {
        try {
            let stored_affinity = localStorage.getItem("eldenring-affinity")
            let stored_baseweapon = localStorage.getItem("eldenring-baseweapon")
            let stored_add = localStorage.getItem("eldenring-add")
            let stored_th = localStorage.getItem("eldenring-twohanding")
            let stored_compare = localStorage.getItem("eldenring-compare")
            if (stored_affinity) setAffinity(stored_affinity !== "None" ? stored_affinity : "")
            if (stored_baseweapon) setBaseWeapon(stored_baseweapon)
            if (stored_add) setAdd(stored_add)
            if (stored_th) setTwoHanding(JSON.parse(stored_th))
            if (stored_compare) setCompare(JSON.parse(stored_compare))
        } catch (err) { }
    }, [])

    useEffect(() => {
        setResult(getWeaponBreakdown(weapon, add, build, str))
        if (compare.length > 0) {
            for (let i = 0; i < compare.length; i++) {
                updateCompare(i, "build", build)
            }
        }
    }, [weapon, build, add, str])

    useEffect(() => {
        if (result?.details?.max && parseInt(add) > result?.details?.max) setAdd(" +" + result?.details?.max)
    }, [result])

    function getWeaponList() {
        return Object.entries(affinities).map(e => {
            return e[1].map(f => {
                if (f !== "None") return f + " " + e[0]
                else return e[0]
            })
        }).flat()
    }

    function findOptimal() {
        let arr = []
        let all_weapons = getWeaponList()
        for (let i = 0, size = all_weapons.length; i < size; i++) {
            let breakdown = getWeaponBreakdown(all_weapons[i], "", build, str)
            arr.push(breakdown)
            let max = breakdown.details.max
            for (let j = 1; j <= max; j++) {
                let breakdown_next = getWeaponBreakdown(all_weapons[i], " +" + j, build, str)
                arr.push(breakdown_next)
            }
        }

        setLoading(false)
        setOptimal(arr.sort((a, b) => {
            if (a.ar.total < b.ar.total) return 1
            else if (a.ar.total > b.ar.total) return -1
            else return 0
        }))
    }

    useEffect(() => {
        if (loading) findOptimal()
    }, [loading])

    useEffect(() => {
        if (fullScreen) {
            if (document.body.clientWidth < 1440) {
                document.getElementById("body").classList.add("large")
                document.getElementById("menu").classList.add("large")
            }
        } else {
            document.getElementById("body").classList.remove("large")
            document.getElementById("menu").classList.remove("large")
        }
    }, [fullScreen])

    function handleResize() {
        setFullWidth(document.body.clientWidth)
        setFullScreen(false)
        document.getElementById("body").classList.remove("large")
        document.getElementById("menu").classList.remove("large")
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", () => { })
        }
    }, [])

    useEffect(() => {
        try {
            localStorage.setItem("eldenring-compare", JSON.stringify(compare))
        } catch (err) { }
    }, [compare])

    if (optimal) return (
        <Optimised optimal={optimal} setOptimal={setOptimal} build={build} />
    )
    else return (
        <div id="weapon-calculator">
            <div id="weapons" className={fullScreen || fullWidth > 1440 ? "weapons large" : "weapons"}>
                {loading ? <div className='loader'>
                    <div className='spinner'></div>
                </div> : null}

                <div id="input" className={fullScreen || fullWidth > 1440 ? "large" : ""}>
                    {fullWidth < 1440 && fullWidth > 1200 ?
                        !fullScreen ? <div className='fullscreen' onClick={() => setFullScreen(!fullScreen)}><Icon.ArrowsAngleExpand /></div>
                            : <div className='fullscreen' onClick={() => setFullScreen(!fullScreen)}><Icon.ArrowsAngleContract /></div>
                        : null
                    }

                    <div className='title'>
                        What damage can you produce with your stats?
                    </div>
                    <div className='sub-title'>
                        Enter your build and weapon details and we'll give you a full damage breakdown
                    </div>

                    <div className='levels'>
                        {getLevel("Strength", "strength", 8)}
                        {getLevel("Dexterity", "dexterity", 8)}
                        {getLevel("Intelligence", "intelligence", 7)}
                        {getLevel("Faith", "faith", 6)}
                        {getLevel("Arcane", "arcane", 7)}
                    </div>

                    <div className='optimal_weapon'>
                        <button onClick={() => {
                            setLoading(true)
                            // findOptimal()
                        }}>Calculate your optimal weapon</button>
                    </div>

                    <div className='or'>Or enter a specific weapon <div className='arrow' id="arrow">{fullScreen || fullWidth > 1440 ? <Icon.ArrowRightShort /> : <Icon.ArrowDownShort />}</div></div>
                </div>

                <div id="result" className={fullScreen || fullWidth > 1440 ? "large" : ""}>
                    <div className='weapon_input'>
                        <div className='weapon_details'>
                            <div className='label'>Affinity</div>
                            {Object.keys(result).length > 0 ?
                                <select className='select' defaultValue={affinity} onChange={e => {
                                    storeWeapon(e.currentTarget.value, baseWeapon, add, twoHanding)
                                    setAffinity(e.currentTarget.value)
                                }}>
                                    {affinities[baseWeapon].map(e => {
                                        return <option value={e}>{e}</option>
                                    })}
                                </select>
                                : <select className='select' defaultValue={affinity} onChange={e => {
                                    storeWeapon(e.currentTarget.value, baseWeapon, add, twoHanding)
                                    setAffinity(e.currentTarget.value)
                                }}>
                                    <option value="None">None</option>
                                    <option value="Heavy">Heavy</option>
                                    <option value="Keen">Keen</option>
                                    <option value="Quality">Quality</option>
                                    <option value="Fire">Fire</option>
                                    <option value="Flame Art">Flame Art</option>
                                    <option value="Lightning">Lightning</option>
                                    <option value="Sacred">Sacred</option>
                                    <option value="Magic">Magic</option>
                                    <option value="Cold">Cold</option>
                                    <option value="Poison">Poison</option>
                                    <option value="Blood">Blood</option>
                                    <option value="Occult">Occult</option>
                                </select>
                            }
                        </div>

                        <div className='weapon_details'>
                            <div className='label'>Weapon</div>
                            {/* {is_firefox && is_phone ? */}
                            <div style={{ display: is_firefox && is_phone ? "none" : "" }} className='search_icon' onClick={() => {
                                setSearch(!search)
                            }}>{!search ? <Icon.Search /> : <Icon.X />}</div>
                            {!search ? <select style={{ paddingLeft: is_firefox && is_phone ? "0px" : "4px" }} key={`${Math.floor((Math.random() * 1000))}-min`} className='datalist' defaultValue={baseWeapon} onChange={e => {
                                if (Object.keys(affinities).map(e => { return e }).includes(e.currentTarget.value)) {
                                    storeWeapon(affinity, e.currentTarget.value, add, twoHanding)
                                    setBaseWeapon(e.currentTarget.value)
                                }
                            }}>
                                {Object.entries(weapon_types).sort((a, b) => {
                                    if (a[0] > b[0]) return 1
                                    else if (a[0] < b[0]) return -1
                                    else return 0
                                }).map(e => {
                                    return <optgroup label={e[0]}>
                                        {e[1].sort().map(f => {
                                            return <option value={f}>{f}</option>
                                        })}
                                    </optgroup>
                                })}
                            </select>
                                : <div style={{ display: "inline-block", maxWidth: "calc(100% - 43px)", width: "300px" }}>
                                    <input id="input_weapon" style={{ paddingLeft: "8px", paddingBottom: "6px", width: "calc(100% - 8px)", maxWidth: "100%", borderRadius: "0px" }} key={`${Math.floor((Math.random() * 1000))}-min`} className='datalist' list="weapon_names" defaultValue={baseWeapon} onChange={e => {
                                        if (Object.keys(affinities).map(e => { return e }).includes(e.currentTarget.value)) {
                                            storeWeapon(affinity, e.currentTarget.value, add, twoHanding)
                                            setBaseWeapon(e.currentTarget.value)
                                        }
                                    }}></input>
                                    <datalist id="weapon_names">
                                        {Object.keys(affinities).sort().map(e => {
                                            return <option value={e} />
                                        })}
                                    </datalist>
                                </div>
                            }
                        </div>

                        <div className='weapon_details'>
                            <div className='label'>Upgrade Level</div>
                            <select key={`${Math.floor((Math.random() * 1000))}-min`} className='select' defaultValue={add} onChange={e => {
                                storeWeapon(affinity, baseWeapon, e.currentTarget.value, twoHanding)
                                setAdd(e.currentTarget.value)
                            }}>
                                {[" +0", " +1", " +2", " +3", " +4", " +5", " +6", " +7", " +8", " +9", " +10", " +11", " +12", " +13", " +14", " +15", " +16", " +17", " +18", " +19", " +20", " +21", " +22", " +23", " +24", " +25"].filter((e, n) => n <= result?.details?.max).map(e => {
                                    if (e === " +0") return <option value="">{e}</option>
                                    else return <option value={e}>{e}</option>
                                })}
                            </select>
                        </div>
                    </div>

                    {Object.keys(result).length > 0 ?
                        <Weapon n={compare.length > 0 ? 1 : null} result={result} add={add} baseWeapon={baseWeapon} affinity={affinity} twoHanding={twoHanding} setTwoHanding={setTwoHanding} str={str} strength={props.build.strength} dex={dex} int={int} fth={fth} arc={arc} />
                        : null
                    }

                    {compare.map((ev, n) => {
                        return <div key={n}>
                            <hr />
                            <div className='weapon_input'>
                                <div className='x' onClick={() => {
                                    let comp = [...compare]
                                    comp.splice(n, 1)
                                    setCompare(comp)
                                }}>
                                    <Icon.X />
                                </div>
                                <div className='weapon_details'>
                                    <div className='label'>Affinity</div>
                                    {Object.keys(result).length > 0 ?
                                        <select className='select' defaultValue={ev.affinity} onChange={e => {
                                            updateCompare(n, "affinity", e.currentTarget.value)
                                        }}>
                                            {affinities[ev.baseWeapon].map(e => {
                                                return <option value={e}>{e}</option>
                                            })}
                                        </select>
                                        : <select className='select' defaultValue={ev.affinity} onChange={e => {
                                            updateCompare(n, "affinity", e.currentTarget.value)
                                        }}>
                                            <option value="None">None</option>
                                            <option value="Heavy">Heavy</option>
                                            <option value="Keen">Keen</option>
                                            <option value="Quality">Quality</option>
                                            <option value="Fire">Fire</option>
                                            <option value="Flame Art">Flame Art</option>
                                            <option value="Lightning">Lightning</option>
                                            <option value="Sacred">Sacred</option>
                                            <option value="Magic">Magic</option>
                                            <option value="Cold">Cold</option>
                                            <option value="Poison">Poison</option>
                                            <option value="Blood">Blood</option>
                                            <option value="Occult">Occult</option>
                                        </select>
                                    }
                                </div>

                                <div className='weapon_details'>
                                    <div className='label'>Weapon</div>
                                    {/* {is_firefox && is_phone ? */}
                                    <div style={{ display: is_firefox && is_phone ? "none" : "" }} className='search_icon' onClick={() => {
                                        updateCompare(n, "search", ev.search ? false : true)
                                    }}>{!ev.search ? <Icon.Search /> : <Icon.X />}</div>
                                    {!ev.search ? <select style={{ paddingLeft: is_firefox && is_phone ? "0px" : "4px" }} key={`${Math.floor((Math.random() * 1000))}-min`} className='datalist' defaultValue={ev.baseWeapon} onChange={e => {
                                        if (Object.keys(affinities).map(e => { return e }).includes(e.currentTarget.value)) {
                                            updateCompare(n, "baseWeapon", e.currentTarget.value)
                                        }
                                    }}>
                                        {Object.entries(weapon_types).sort((a, b) => {
                                            if (a[0] > b[0]) return 1
                                            else if (a[0] < b[0]) return -1
                                            else return 0
                                        }).map(e => {
                                            return <optgroup label={e[0]}>
                                                {e[1].sort().map(f => {
                                                    return <option value={f}>{f}</option>
                                                })}
                                            </optgroup>
                                        })}
                                    </select>
                                        : <div style={{ display: "inline-block", maxWidth: "calc(100% - 43px)", width: "300px" }}>
                                            <input id="input_weapon" style={{ paddingLeft: "8px", paddingBottom: "6px", width: "calc(100% - 8px)", maxWidth: "100%", borderRadius: "0px" }} key={`${Math.floor((Math.random() * 1000))}-min`} className='datalist' list="weapon_names" defaultValue={ev.baseWeapon} onChange={e => {
                                                if (Object.keys(affinities).map(e => { return e }).includes(e.currentTarget.value)) {
                                                    updateCompare(n, "baseWeapon", e.currentTarget.value)
                                                }
                                            }}></input>
                                            <datalist id="weapon_names">
                                                {Object.keys(affinities).sort().map(e => {
                                                    return <option value={e} />
                                                })}
                                            </datalist>
                                        </div>
                                    }
                                </div>

                                <div className='weapon_details'>
                                    <div className='label'>Upgrade Level</div>
                                    <select key={`${Math.floor((Math.random() * 1000))}-min`} className='select' defaultValue={ev.add} onChange={e => {
                                        updateCompare(n, "add", e.currentTarget.value)
                                    }}>
                                        {[" +0", " +1", " +2", " +3", " +4", " +5", " +6", " +7", " +8", " +9", " +10", " +11", " +12", " +13", " +14", " +15", " +16", " +17", " +18", " +19", " +20", " +21", " +22", " +23", " +24", " +25"].filter((e, n) => n <= ev.result?.details?.max).map(e => {
                                            if (e === " +0") return <option value="">{e}</option>
                                            else return <option value={e}>{e}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            {
                                Object.keys(ev.result).length > 0 ?
                                    <Weapon n={n + 2} compare={result} result={ev.result} add={ev.add} baseWeapon={ev.baseWeapon} affinity={ev.affinity} twoHanding={ev.twoHanding} setTwoHanding={() => updateCompare(n, "twoHanding", !ev.twoHanding)} str={str} strength={props.build.strength} dex={dex} int={int} fth={fth} arc={arc} />
                                    : null
                            }
                        </div>
                    })}

                    <div className='add_weapon_button' onClick={() => {
                        setCompare([
                            ...compare,
                            {
                                baseWeapon: "Dagger",
                                affinity: "",
                                add: "",
                                twoHanding: false,
                                str: str,
                                result: getWeaponBreakdown("Dagger", "", build, str)
                            }
                        ])
                    }}>Add another weapon</div>
                </div>
            </div>
        </div>
    );

    function updateCompare(n, key, value) {
        if (value === "None") {
            value = ""
        }

        let comp = [...compare]
        comp.splice(n, 1, {
            ...comp[n],
            [key]: value
        })
        comp.splice(n, 1, {
            ...comp[n],
            result: getWeaponBreakdown(comp[n].affinity ? `${comp[n].affinity} ${comp[n].baseWeapon}` : comp[n].baseWeapon, comp[n].add, build, comp[n].twoHanding ? Math.floor(str * 1.5) : build.strength)
        })

        console.log(comp)
        setCompare(comp)
    }

    function getLevel(title, variable, min) {
        return <div className='level'>
            <div className='title'>
                {title}
            </div>
            <input className='level_value' type="number" value={build[variable]} onChange={e => {
                props.setBuild({
                    ...build,
                    [variable]: parseInt(e.currentTarget.value)
                })
            }} />
            {/* <div className='level_value'>{build[variable]}</div> */}
            <div onClick={() => {
                let value = build[variable] - 1
                if (value < min) value = min
                props.setBuild({
                    ...build,
                    [variable]: value
                })
            }} style={{ display: "inline-block", fontSize: "22px", verticalAlign: "top", marginBottom: "-7px", marginRight: "7px", cursor: "pointer" }}><Icon.Dash /></div>
            <div className='slider_container'>
                <input type="range" min={min} max="99" value={build[variable]} className="slider" id="myRange" onChange={e => {
                    props.setBuild({
                        ...build,
                        [variable]: parseInt(e.currentTarget.value)
                    })
                }} />
                <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", marginTop: "0px", marginBottom: "-20px", fontWeight: "600" }}>
                    <div className="slider_label" style={{ color: "#002b36", fontSize: "12px" }}>
                        {min}
                    </div>
                    <div className="slider_label" style={{ color: "#002b36", fontSize: "12px" }}>
                        99
                    </div>
                </div>
            </div>
            <div onClick={() => {
                let value = build[variable] + 1
                if (value > 99) value = 99
                props.setBuild({
                    ...build,
                    [variable]: value
                })
            }} style={{ display: "inline-block", fontSize: "22px", verticalAlign: "top", marginBottom: "-7px", marginLeft: "7px", cursor: "pointer" }}><Icon.Plus /></div>
        </div>
    }
}

export default Weapons;