import history from "./history";
import { Router, Switch, Route } from "react-router";
import All from "./all";
import "./index.sass"
import { useEffect } from "react";

function App() {
	function onResize() {
		let width = document.getElementById("resize_detector")
		if (width) {
			width = width.clientWidth
			if (width >= 990) {
				if (document.getElementById("menu")) {
					document.getElementById("menu").style.transform = "translate(0px, 0px)"
				}
			} else {
				if (document.getElementById("menu")) {
					document.getElementById("menu").style.transform = "translate(calc(-100% - 1px), 0px)"
				}
			}
		}
	}

	useEffect(() => {
		window.addEventListener("resize", onResize)

		return () => {
			window.removeEventListener("resize", () => { })
		}
	}, [])

	return (
		<div className="App">
			<video loop autoPlay muted className="video_background" playsInline disablePictureInPicture>
				<source src="https://static.bandainamcoent.eu/video/elden-ring-header-animated.mp4" type="video/mp4" />
			</video>

			<div id="resize_detector">
				<Router history={history}>
					<Switch>
						<Route exact path="/" component={All} />
						<Route path="/:calculator" component={All} />
					</Switch>
				</Router>
			</div>
		</div>
	);
}

export default App;
