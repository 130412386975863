import React from 'react';
import * as Icon from "react-bootstrap-icons"

function Heading(props) {
    const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const is_android = navigator.userAgent.toLowerCase().indexOf("android") > -1;
    const is_iphone = navigator.userAgent.toLowerCase().indexOf("iphone") > -1
    const is_phone = is_android || is_iphone

    return (
        <div className='heading'>
            <div className='burger' onClick={() => {
                document.getElementById("menu").style.transform = "translate(-1px, 0px)"
            }}>
                <Icon.List />
            </div>
            <div style={{width: is_firefox && is_phone ? "calc(100% - 70px)" : ""}} className="logo_box">
                <img className="logo" alt="Elden ring" src="https://p325k7wa.twic.pics/high/elden-ring/elden-ring/00-page-setup/eldenring_new.png?twic=v1/cover=650x217/step=10/quality=80" />
                {/* <div className="sub">
                    CALCULATORS
                </div> */}
            </div>
        </div>
    );
}

export default Heading;