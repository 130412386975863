import React from 'react';
import "./footer.sass"

function Footer(props) {
    return (
        <div className='footer'>

            <div className="credit">
                Love this site? <a href="https://ko-fi.com/roryd" target="_blank" rel='noreferrer' onClick={() => window.open("https://ko-fi.com/roryd")}>Buy me a coffee!</a>
                <hr />
                {/* <a href="https://www.buymeacoffee.com/rorydobson" target="_blank" rel="noreferrer"><img onClick={() => {
                    window.open("https://www.buymeacoffee.com/rorydobson")
                }} src="https://cdn.buymeacoffee.com/buttons/v2/default-black.png" alt="Buy Me A Coffee" style={{ height: "50px", width: "187px" }} /></a> */}
                Have a comment or suggestion? Email me at <a href="mailto:eldenring@rorydobson.com" target="_blank" rel='noreferrer' onClick={() => window.open("mailto:eldenring@rorydobson.com")}>eldenring@rorydobson.com</a>
                <hr />
                Made by <a href="https://rorydobson.com" target="_blank" rel='noreferrer' onClick={() => window.open("https://rorydobson.com")}>Rory Dobson</a>
            </div>
        </div>
    );
}

export default Footer;