const calc = require("./weapon_correct.json")
const correct = require("./calc_correct.json")
const weapons = require("./weapons.json")
const scaling = require("./scaling.json")
const requirements = require("./requirements.json")
const attack = require("./attack_element.json")
const letters = require("./scaling_letters.json")
const speed = require("./weapon_speed.json")
const passive = require("./passive.json")
const poise = require("./poise_damage.json")

export default function getWeaponBreakdown(name, extra, build, str) {
    let wep = name

    let addd = ""
    if (extra) addd = extra

    let obj = {
        base: {
            physical: parseInt(weapons[wep]["Phys" + addd]),
            magic: parseInt(weapons[wep]["Mag" + addd]),
            fire: parseInt(weapons[wep]["Fire" + addd]),
            lightning: parseInt(weapons[wep]["Ligh" + addd]),
            holy: parseInt(weapons[wep]["Holy" + addd])
        },
        required: {
            strength: requirements[wep]["Required (Str)"],
            dexterity: requirements[wep]["Required (Dex)"],
            intelligence: requirements[wep]["Required (Int)"],
            faith: requirements[wep]["Required (Fai)"],
            arcane: requirements[wep]["Required (Arc)"]
        },
        scaling: {},
        letters: {
            strength: {
                letter: letters[wep]["Str" + addd],
                value: scaling[wep]["Str" + addd] * 100
            },
            dexterity: {
                letter: letters[wep]["Dex" + addd],
                value: scaling[wep]["Dex" + addd] * 100
            },
            intelligence: {
                letter: letters[wep]["Int" + addd],
                value: scaling[wep]["Int" + addd] * 100
            },
            faith: {
                letter: letters[wep]["Fai" + addd],
                value: scaling[wep]["Fai" + addd] * 100
            },
            arcane: {
                letter: letters[wep]["Arc" + addd],
                value: scaling[wep]["Arc" + addd] * 100
            }
        },
        ar: {},
        passive: {},
        details: {
            name: wep,
            level: addd,
            weight: requirements[wep].Weight,
            type: requirements[wep]["Weapon Type"],
            damage_type: requirements[wep]["Physical Damage Type"],
            max: parseInt(requirements[wep]["Max Upgrade"]),
            poise: parseFloat(requirements[wep]["Base Poise Attack"])
        },
        stagger: {}
    }

    if (poise?.[requirements?.[wep]?.["Weapon Type"]]?.["Standing Light Attack"]) obj.stagger["light"] = poise[requirements[wep]["Weapon Type"]]["Standing Light Attack"] * parseFloat(requirements[wep]["Base Poise Attack"])
    if (poise?.[requirements?.[wep]?.["Weapon Type"]]?.["Jumping Light Attack"]) obj.stagger["jumping_light"] = poise[requirements[wep]["Weapon Type"]]["Jumping Light Attack"] * parseFloat(requirements[wep]["Base Poise Attack"])
    if (poise?.[requirements?.[wep]?.["Weapon Type"]]?.["Standing Heavy Attack"]) obj.stagger["heavy"] = poise[requirements[wep]["Weapon Type"]]["Standing Heavy Attack"] * parseFloat(requirements[wep]["Base Poise Attack"])
    if (poise?.[requirements?.[wep]?.["Weapon Type"]]?.["Jumping Heavy Attack"]) obj.stagger["jumping_heavy"] = poise[requirements[wep]["Weapon Type"]]["Jumping Heavy Attack"] * parseFloat(requirements[wep]["Base Poise Attack"])
    if (poise?.[requirements?.[wep]?.["Weapon Type"]]?.["Fully Charged Heavy Attack"]) obj.stagger["charged_heavy"] = poise[requirements[wep]["Weapon Type"]]["Fully Charged Heavy Attack"] * parseFloat(requirements[wep]["Base Poise Attack"])
    if (poise?.[requirements?.[wep]?.["Weapon Type"]]?.["Dual Wielding Attack(s)"]) obj.stagger["dual_wielding"] = poise[requirements[wep]["Weapon Type"]]["Dual Wielding Attack(s)"] * parseFloat(requirements[wep]["Base Poise Attack"])
    if (poise?.[requirements?.[wep]?.["Weapon Type"]]?.["Jumping Dual Wield Attacks"]) obj.stagger["jumping_dual_wielding"] = poise[requirements[wep]["Weapon Type"]]["Jumping Dual Wield Attacks"] * parseFloat(requirements[wep]["Base Poise Attack"])
    if (poise?.[requirements?.[wep]?.["Weapon Type"]]?.["Guard Counter"]) obj.stagger["guard_counter"] = poise[requirements[wep]["Weapon Type"]]["Guard Counter"] * parseFloat(requirements[wep]["Base Poise Attack"])

    obj.scaling["physical"] = getStat(wep, addd, "Phys", "physical", build, str)
    obj.scaling["magic"] = getStat(wep, addd, "Mag", "magic", build, str)
    obj.scaling["fire"] = getStat(wep, addd, "Fire", "fire", build, str)
    obj.scaling["lightning"] = getStat(wep, addd, "Ligh", "lightning", build, str)
    obj.scaling["holy"] = getStat(wep, addd, "Holy", "holy", build, str)

    obj.ar["physical"] = obj.base.physical + obj.scaling.physical
    obj.ar["magic"] = obj.base.magic + obj.scaling.magic
    obj.ar["fire"] = obj.base.fire + obj.scaling.fire
    obj.ar["lightning"] = obj.base.lightning + obj.scaling.lightning
    obj.ar["holy"] = obj.base.holy + obj.scaling.holy
    obj.ar["total"] = obj.ar.physical + obj.ar.magic + obj.ar.fire + obj.ar.lightning + obj.ar.holy

    obj.passive["scarlet rot"] = Math.floor(getPassiveScaling(wep, addd, "Scarlet Rot", build))
    obj.passive["madness"] = Math.floor(getPassiveScaling(wep, addd, "Madness", build))
    obj.passive["sleep"] = Math.floor(getPassiveScaling(wep, addd, "Sleep", build))
    obj.passive["frost"] = Math.floor(getPassiveScaling(wep, addd, "Frost", build))
    obj.passive["poison"] = Math.floor(getPassiveScaling(wep, addd, "Poison", build))
    obj.passive["blood"] = Math.floor(getPassiveScaling(wep, addd, "Blood", build))
    obj.passive["total"] = obj.passive["scarlet rot"] + obj.passive["madness"] + obj.passive["sleep"] + obj.passive["frost"] + obj.passive["poison"] + obj.passive["blood"]

    obj.ar.dps = parseInt(obj.ar.total / 60 * speed[requirements[wep]["Weapon Type"]])

    return obj
}

function getStat(wep, addd, type, stat, build, str) {
    if (parseInt(weapons[wep][type + addd]) > 0) {
        let str_correct = getCorrection(wep, str, stat[0].toUpperCase() + stat.slice(1))
        let dex_correct = getCorrection(wep, build.dexterity, stat[0].toUpperCase() + stat.slice(1))
        let int_correct = getCorrection(wep, build.intelligence, stat[0].toUpperCase() + stat.slice(1))
        let fth_correct = getCorrection(wep, build.faith, stat[0].toUpperCase() + stat.slice(1))
        let arc_correct = getCorrection(wep, build.arcane, stat[0].toUpperCase() + stat.slice(1))

        let str_scaling = getScaling(wep, addd, type, str_correct, parseFloat(scaling[wep]["Str" + addd]))
        let dex_scaling = getScaling(wep, addd, type, dex_correct, parseFloat(scaling[wep]["Dex" + addd]))
        let int_scaling = getScaling(wep, addd, type, int_correct, parseFloat(scaling[wep]["Int" + addd]))
        let fth_scaling = getScaling(wep, addd, type, fth_correct, parseFloat(scaling[wep]["Fai" + addd]))
        let arc_scaling = getScaling(wep, addd, type, arc_correct, parseFloat(scaling[wep]["Arc" + addd]))

        let sum = 0
        if (attack[calc[wep]["AttackElementCorrect ID"]][`${stat[0].toUpperCase() + stat.slice(1)} Scaling: STR`] === 1) {
            if (requirements[wep]["Required (Str)"] > str) sum = parseInt(weapons[wep][type + addd]) * -0.4
            else sum += str_scaling
        }
        if (sum >= 0 && attack[calc[wep]["AttackElementCorrect ID"]][`${stat[0].toUpperCase() + stat.slice(1)} Scaling: DEX`] === 1) {
            if (requirements[wep]["Required (Dex)"] > build.dexterity) sum = parseInt(weapons[wep][type + addd]) * -0.4
            else sum += dex_scaling
        }
        if (sum >= 0 && attack[calc[wep]["AttackElementCorrect ID"]][`${stat[0].toUpperCase() + stat.slice(1)} Scaling: INT`] === 1) {
            if (requirements[wep]["Required (Int)"] > build.intelligence) sum = parseInt(weapons[wep][type + addd]) * -0.4
            else sum += int_scaling
        }
        if (sum >= 0 && attack[calc[wep]["AttackElementCorrect ID"]][`${stat[0].toUpperCase() + stat.slice(1)} Scaling: FAI`] === 1) {
            if (requirements[wep]["Required (Fai)"] > build.faith) sum = parseInt(weapons[wep][type + addd]) * -0.4
            else sum += fth_scaling
        }
        if (sum >= 0 && attack[calc[wep]["AttackElementCorrect ID"]][`${stat[0].toUpperCase() + stat.slice(1)} Scaling: ARC`] === 1) {
            if (requirements[wep]["Required (Arc)"] > build.arcane) sum = parseInt(weapons[wep][type + addd]) * -0.4
            else sum += arc_scaling
        }

        if (sum > 0) return Math.floor(sum)
        else return Math.ceil(sum)
    } else return 0
}

function getCorrection(wep, value, scale) {
    switch (calc[wep][scale]) {
        case (0):
            if (value > correct[calc[wep][scale]]["Stat Max 3"]) return 90 + (20 * ((value - 80) / 70))
            else if (value > correct[calc[wep][scale]]["Stat Max 2"]) return 75 + (15 * ((value - 60) / 20))
            else if (value > correct[calc[wep][scale]]["Stat Max 1"]) return 25 + (50 * (1 - Math.pow(1 - ((value - 18) / 42), 1.2)))
            else return 25 * Math.pow((value - 1) / 17, 1.2)

        case (1):
            if (value > correct[calc[wep][scale]]["Stat Max 3"]) return 90 + (20 * ((value - 80) / 70))
            else if (value > correct[calc[wep][scale]]["Stat Max 2"]) return 75 + (15 * ((value - 60) / 20))
            else if (value > correct[calc[wep][scale]]["Stat Max 1"]) return 35 + (40 * (1 - Math.pow(1 - ((value - 20) / 40), 1.2)))
            else return 35 * Math.pow((value - 1) / 19, 1.2)

        case (2):
            if (value > correct[calc[wep][scale]]["Stat Max 3"]) return 90 + (20 * ((value - 80) / 70))
            else if (value > correct[calc[wep][scale]]["Stat Max 2"]) return 75 + (15 * ((value - 60) / 20))
            else if (value > correct[calc[wep][scale]]["Stat Max 1"]) return 35 + (40 * (1 - Math.pow(1 - ((value - 20) / 40), 1.2)))
            else return 35 * Math.pow((value - 1) / 19, 1.2)

        case (4):
            if (value > correct[calc[wep][scale]]["Stat Max 3"]) return 95 + (5 * ((value - 80) / 19))
            else if (value > correct[calc[wep][scale]]["Stat Max 2"]) return 80 + (15 * ((value - 50) / 30))
            else if (value > correct[calc[wep][scale]]["Stat Max 1"]) return 40 + (40 * ((value - 20) / 30))
            else return 40 * ((value - 1) / 19)

        case (7):
            if (value > correct[calc[wep][scale]]["Stat Max 3"]) return 90 + (20 * ((value - 80) / 70))
            else if (value > correct[calc[wep][scale]]["Stat Max 2"]) return 75 + (15 * ((value - 60) / 20))
            else if (value > correct[calc[wep][scale]]["Stat Max 1"]) return 35 + (40 * (1 - Math.pow(1 - ((value - 20) / 40), 1.2)))
            else return 35 * Math.pow((value - 1) / 19, 1.2)

        case (8):
            if (value > correct[calc[wep][scale]]["Stat Max 3"]) return 90 + (20 * ((value - 80) / 70))
            else if (value > correct[calc[wep][scale]]["Stat Max 2"]) return 75 + (15 * ((value - 60) / 20))
            else if (value > correct[calc[wep][scale]]["Stat Max 1"]) return 25 + (50 * (1 - Math.pow(1 - ((value - 16) / 44), 1.2)))
            else return 25 * Math.pow((value - 1) / 15, 1.2)

        case (12):
            if (value > correct[calc[wep][scale]]["Stat Max 3"]) return 75 + (25 * ((value - 45) / 54))
            else if (value > correct[calc[wep][scale]]["Stat Max 2"]) return 55 + (20 * ((value - 30) / 15))
            else if (value > correct[calc[wep][scale]]["Stat Max 1"]) return 10 + (45 * ((value - 15) / 15))
            else return 10 * ((value - 1) / 14)

        case (14):
            if (value > correct[calc[wep][scale]]["Stat Max 3"]) return 85 + (15 * ((value - 80) / 19))
            else if (value > correct[calc[wep][scale]]["Stat Max 2"]) return 60 + (25 * ((value - 40) / 40))
            else if (value > correct[calc[wep][scale]]["Stat Max 1"]) return 40 + (20 * ((value - 20) / 20))
            else return 40 * ((value - 1) / 19)

        case (15):
            if (value > correct[calc[wep][scale]]["Stat Max 3"]) return 95 + (5 * ((value - 80) / 19))
            else if (value > correct[calc[wep][scale]]["Stat Max 2"]) return 65 + (30 * ((value - 60) / 20))
            else if (value > correct[calc[wep][scale]]["Stat Max 1"]) return 25 + (40 * ((value - 25) / 35))
            else return 25 * ((value - 1) / 24)

        case (16):
            if (value > correct[calc[wep][scale]]["Stat Max 3"]) return 90 + (10 * ((value - 80) / 19))
            else if (value > correct[calc[wep][scale]]["Stat Max 2"]) return 75 + (15 * ((value - 60) / 20))
            else if (value > correct[calc[wep][scale]]["Stat Max 1"]) return 20 + (55 * ((value - 18) / 42))
            else return 20 * ((value - 1) / 17)

        default:
            return 0
    }
}

function getScaling(wep, addd, type, correction, percent) {
    if (weapons[wep][type + addd] > 0) {
        return weapons[wep][type + addd] * (percent * (correction / 100))
    } else return 0
}

function getPassiveScaling(wep, addd, type, build) {
    switch (type) {
        case "Scarlet Rot": return passive[wep]["Scarlet Rot"]
        case "Madness": return passive[wep]["Madness"]
        case "Sleep": return passive[wep]["Sleep"]
        case "Poison": {
            if(scaling[wep]["Arc" + addd] > 0) {
                return (scaling[wep]["Arc" + addd] * (passiveArcaneCalcCorrect(build) * passive[wep]["Poison" + addd])) + passive[wep]["Poison" + addd]
            } else return passive[wep]["Poison" + addd]
        }
        case "Blood": {
            if(scaling[wep]["Arc" + addd] > 0) {
                return (scaling[wep]["Arc" + addd] * (passiveArcaneCalcCorrect(build) * passive[wep]["Blood" + addd])) + passive[wep]["Blood" + addd]
            } else return passive[wep]["Blood" + addd]
        }
        case "Frost": return passive[wep]["Frost" + addd]
        default: return 0
    }
}

function passiveArcaneCalcCorrect(build) {
    let div = 0
    if (build.arcane > 60) div = (90 + (10 * ((build.arcane - 60) / 39)))
    else if (build.arcane > 45) div = (75 + (15 * ((build.arcane - 45) / 15)))
    else if (build.arcane > 25) div = (10 + (65 * ((build.arcane - 25) / 20)))
    else div = (10 * ((build.arcane - 1) / 24))

    return div / 100
}