import React, { useEffect, useState } from 'react';
import Optimised from './components/builds/optimised';
import BottomAdd from './components/footer/bottom_add';
// import Footer from './components/footer/footer';
import Heading from './components/footer/heading';
import Consumables from './components/levels/consumables';
import Runes from './components/levels/runes';
import Nav from './components/navigation/nav';
import Weapons from './components/weapon/weapons';
import history from './history';

function All(props) {
    
    const [build, setBuild] = useState({
        vigor: 9,
        mind: 9,
        endurance: 8,
        strength: 8,
        dexterity: 8,
        intelligence: 7,
        faith: 6,
        arcane: 7
    })

    useEffect(() => {
        try {
            if(localStorage.getItem("eldenring-build")) setBuild(JSON.parse(localStorage.getItem("eldenring-build")))
        } catch (err) { }
    }, [])

    useEffect(() => {
        try {
            localStorage.setItem("eldenring-build", JSON.stringify(build))
        } catch (err) { }
    }, [build])

    const [active, setActive] = useState("rune-calculator")

    useEffect(() => {
        let calc = ["rune-calculator", "build-calculator", "weapon-calculator", "consumables-calculator"]
        if (props?.match?.params?.calculator && calc.includes(props?.match?.params?.calculator)) {
            document.title = "Elden ring " + props?.match?.params?.calculator.replace("-", " ")
            setActive(props?.match?.params?.calculator)
        } else {
            try {
                history.push(localStorage.getItem("eldenring-page"))
            } catch (err) {
                history.push("rune-calculator")
            }
        }
    }, [props?.match?.params?.calculator])

    return (
        <div>
            <Nav />

            <div className="body" id="body">
                <Heading />

                {active === "rune-calculator" ? <Runes /> : null}

                {active === "build-calculator" ? <Optimised build={build} setBuild={setBuild} /> : null}

                {active === "weapon-calculator" ? <Weapons build={build} setBuild={setBuild} /> : null}

                {active === "consumables-calculator" ? <Consumables /> : null}

                <BottomAdd />
            </div>
        </div>
    );
}

export default All;