import React, { useEffect, useState } from 'react';
import * as Icon from "react-bootstrap-icons"
import history from '../../history';
import Footer from '../footer/footer';

function Nav(props) {
    const is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const is_android = navigator.userAgent.toLowerCase().indexOf("android") > -1;
    const is_iphone = navigator.userAgent.toLowerCase().indexOf("iphone") > -1
    const is_phone = is_android || is_iphone

    const [active, setActive] = useState(history.location.pathname.replace("/", "") !== "" ? history.location.pathname.replace("/", "") : "rune-calculator")

    useEffect(() => {
        setActive(history.location.pathname.replace("/", ""))
    }, [history.location.pathname])

    return (
        <div id="menu" className='navigation_container' onClick={() => {
            document.getElementById("menu").style.transform = "translate(calc(-100% - 1px), 0px)"
        }}>
            <div className='navigation' onClick={e => {
                e.preventDefault()
                e.stopPropagation()
            }}>
                <div className='x' onClick={() => {
                    document.getElementById("menu").style.transform = "translate(calc(-100% - 1px), 0px)"
                }}>
                    <Icon.X />
                </div>

                <div className='header'>
                    Menu
                </div>

                {getItem("Rune calculator", "rune-calculator")}
                {getItem("Build calculator", "build-calculator")}
                {getItem("Weapon calculator", "weapon-calculator")}
                {getItem("Consumables calculator", "consumables-calculator")}

                <div style={{ paddingBottom: is_firefox && is_phone ? "60px" : "", position: "absolute", bottom: "0px", left: "0px", width: "100%", marginBottom: "15px" }} className=''>
                    <Footer />
                </div>
            </div>
        </div>
    );

    function getItem(text, id) {
        return <div className={active === id ? 'item active' : 'item'} onClick={() => {
            try {
                localStorage.setItem("eldenring-page", id)
            } catch (err) { }
            setActive(id)
            history.push(id)
            if (document.getElementById("menu")) {
                if (document.getElementById("menu").style.transform === "translate(-1px, 0px)") document.getElementById("menu").style.transform = "translate(calc(-100% - 1px), 0px)"
            }
        }}>
            <div className='text'>
                {text}
            </div>
            <div className='arrow'>
                <Icon.ArrowRightShort />
            </div>
        </div>
    }
}

export default Nav;