import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import "./accordion.sass";

class Accordion extends Component {
    render() {
        return (
            <div className="accordion" style={{ maxHeight: "0px", padding: this.props.noPadding ? "0px" : "", margin: this.props.extendMargin ? "0px -15px" : "" }} id={this.props.id}>
                <ReactResizeDetector handleWidth handleHeight onResize={(w, h) => {
                    if(document.getElementById(this.props.id).style.maxHeight !== "0px") document.getElementById(this.props.id).style.maxHeight = (h + 100) + "px"
                }}>
                    {this.props.children}
                </ReactResizeDetector>
            </div>
        );
    }
}

export default Accordion;