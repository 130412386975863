import React, { useEffect, useState } from 'react';
import * as Icon from "react-bootstrap-icons"
const speed = require("./weapon_speed.json")

function Weapon(props) {
    const [result, setResult] = useState(props.result)

    useEffect(() => {
        setResult(props.result)
    }, [props.result])

    useEffect(() => {
        console.log(result)
    }, [result])

    function storeWeapon(aff, bas, ad, th) {
        try {
            if (aff) {
                if (aff === "None") localStorage.removeItem("eldenring-affinity")
                else localStorage.setItem("eldenring-affinity", aff)
            }
            if (bas) localStorage.setItem("eldenring-baseweapon", bas)
            if (ad || ad === "") localStorage.setItem("eldenring-add", ad)
            localStorage.setItem("eldenring-twohanding", th)
        } catch (err) { }
    }

    return (<div className='weapon_stats'>
        <div>
            <div className='header'>
                {props.n ? `${props.n}. ` : null}{result.details.name + props.add}
            </div>
            <div className='weapon_type'>
                {result.details.type}
            </div>
            <div className="checkbox" onClick={() => {
                storeWeapon(props.affinity, props.baseWeapon, props.add, !props.twoHanding)
                props.setTwoHanding(!props.twoHanding)
            }}>
                <div style={{ backgroundColor: props.twoHanding ? "#002b36" : "transparent" }} className='box' checked={props.twoHanding}>
                    <div style={{ display: props.twoHanding ? "block" : "none" }} className='tick'><Icon.Check2 /></div>
                </div>
                <div className='label'>Two Handing</div>
            </div>
            <div className='requirements'>
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td style={{ borderTopLeftRadius: "3px" }}>STR</td>
                            <td>DEX</td>
                            <td>INT</td>
                            <td>FTH</td>
                            <td style={{ borderTopRightRadius: "3px" }}>ARC</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ borderTopLeftRadius: "3px" }}>Requirements</td>
                            <td style={{ color: result.required.strength > props.str ? "red" : "" }}>{result.required.strength}</td>
                            <td style={{ color: result.required.dexterity > props.dex ? "red" : "" }}>{result.required.dexterity}</td>
                            <td style={{ color: result.required.intelligence > props.int ? "red" : "" }}>{result.required.intelligence}</td>
                            <td style={{ color: result.required.faith > props.fth ? "red" : "" }}>{result.required.faith}</td>
                            <td style={{ color: result.required.arcane > props.arc ? "red" : "" }}>{result.required.arcane}</td>
                        </tr>
                        <tr>
                            <td style={{ borderBottomLeftRadius: "3px" }}>Scaling</td>
                            <td>{result.letters.strength.letter} {result.letters.strength.letter === "-" ? null : `(${parseFloat(result.letters.strength.value).toFixed(1)})`}</td>
                            <td>{result.letters.dexterity.letter} {result.letters.dexterity.letter === "-" ? null : `(${parseFloat(result.letters.dexterity.value).toFixed(1)})`}</td>
                            <td>{result.letters.intelligence.letter} {result.letters.intelligence.letter === "-" ? null : `(${parseFloat(result.letters.intelligence.value).toFixed(1)})`}</td>
                            <td>{result.letters.faith.letter} {result.letters.faith.letter === "-" ? null : `(${parseFloat(result.letters.faith.value).toFixed(1)})`}</td>
                            <td style={{ borderBottomRightRadius: "3px" }}>{result.letters.arcane.letter} {result.letters.arcane.letter === "-" ? null : `(${parseFloat(result.letters.arcane.value).toFixed(1)})`}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className='column'>
            <div className='damage'>
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td style={{ textAlign: "center", fontSize: "20px", padding: "5px 5px 5px 20px" }}>Passive</td>
                        </tr>
                    </thead>
                    <tbody>
                        {getPassiveTr("Scarlet Rot")}
                        {getPassiveTr("Madness")}
                        {getPassiveTr("Sleep")}
                        {getPassiveTr("Frost")}
                        {getPassiveTr("Poison")}
                        {getPassiveTr("Blood")}
                        <tr>
                            <td style={{ border: "none", textAlign: "right", paddingRight: "0px", paddingTop: "10px" }} colSpan={1}>Total Passive</td>
                            <td><div className='ar'>{result.passive.total}</div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className='column'>
            <div className='damage'>
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <td style={{ textAlign: "left", fontSize: "20px", padding: "5px 5px 5px 20px" }} colSpan={2}>Damage</td>
                            <td style={{ textAlign: "left", fontSize: "20px", padding: "5px 5px 5px 20px" }}>Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        {getResultTr("Physical")}
                        {getResultTr("Magic")}
                        {getResultTr("Fire")}
                        {getResultTr("Lightning")}
                        {getResultTr("Holy")}
                        <tr>
                            <td style={{ border: "none", textAlign: "right", paddingRight: "0px", paddingTop: "10px" }} colSpan={3}>Attack Rating</td>
                            <td><div className='ar' style={{color: !props.compare ? "" : result.ar.total - props.compare.ar.total > 0 ? "#00b800" : result.ar.total - props.compare.ar.total < 0 ? "red" : ""}}>{result.ar.total}</div></td>
                        </tr>
                        {calcDPS(result.details.type, result.ar.total) > 0 ?
                            <tr>
                                <td style={{ border: "none", textAlign: "right", paddingRight: "0px", paddingTop: "10px" }} colSpan={3}>DPS</td>
                                <td><div className='ar' style={{color: !props.compare ? "" : calcDPS(result.details.type, result.ar.total) - calcDPS(props.compare.details.type, props.compare.ar.total) > 0 ? "#00b800" : calcDPS(result.details.type, result.ar.total) - calcDPS(props.compare.details.type, props.compare.ar.total) < 0 ? "red" : ""}}>{calcDPS(result.details.type, result.ar.total)}</div></td>
                            </tr>
                            : null}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    );

    function calcDPS(type, ar) {
        let dps = ar * speed[type] / 60
        return parseInt(dps)
    }

    function getResultTr(att) {
        return <tr>
            <td>{att}</td>
            <td><div className='dmg'>{result.base[att.toLowerCase()]}</div></td>
            <td style={{ color: result.scaling[att.toLowerCase()] < 0 ? "red" : result.scaling[att.toLowerCase()] > 0 ? "green" : "" }}>{result.scaling[att.toLowerCase()] >= 0 ? `+${result.scaling[att.toLowerCase()]}` : result.scaling[att.toLowerCase()]}</td>
            <td style={{color: !props.compare ? "" : result.ar[att.toLowerCase()] - props.compare.ar[att.toLowerCase()] > 0 ? "green" : result.ar[att.toLowerCase()] - props.compare.ar[att.toLowerCase()] < 0 ? "red" : ""}}>{result.ar[att.toLowerCase()]}</td>
        </tr>
    }

    function getPassiveTr(att) {
        return <tr>
            <td>{att === "Blood" ? "Bleed" : att}</td>
            <td><div className='dmg' style={{ margin: "auto" }}>{result.passive[att.toLowerCase()]}</div></td>
        </tr>
    }

}

export default Weapon;