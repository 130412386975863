import eldenring from "elden-ring"
import React, { useEffect, useState } from 'react';
import commaNumber from "comma-number";
import AccordionButton from "../Accordion/AccordionButton";
import Accordion from "../Accordion/Accordion";

function Runes(props) {
    const [current, setCurrent] = useState(null)
    const [desired, setDesired] = useState(null)

    function getBreakdown() {
        if (current > 0 && current < 713 && desired > 1 && desired <= 713) {
            let arr = []
            for (let i = current; i < desired; i++) {
                let lvl = eldenring.allLevels()[i + 1]
                if (i > current) arr.push(<tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right", fontSize: "14px", marginTop: "-10px" }}>+{commaNumber(eldenring.allLevels()[i + 1].increase)}</td>
                    <td></td>
                    <td></td>
                </tr>)
                arr.push(
                    <tr>
                        <td><b>{i}</b></td>
                        <td>&nbsp;→&nbsp;</td>
                        <td><b>{i + 1}</b></td>
                        <td>&nbsp;=&nbsp;</td>
                        <td style={{ textAlign: "right" }}><b>{commaNumber(lvl.runes)}</b></td>
                        <td>&nbsp;&nbsp;&nbsp;Total:</td>
                        <td><b>{commaNumber(eldenring.allLevels()[i + 1].total - eldenring.allLevels()[current].total)}</b></td>
                    </tr>
                )
            }
            return <table style={{ margin: "15px auto", height: "100%" }}>
                <tbody>
                    {arr}
                </tbody>
            </table>
        } else return null
    }

    return (
        <div id="rune-calculator">
            <div className="runes_container">
                <div className="runes">
                    <div className="title">How many runes do you need?</div>

                    <div className="input_box">
                        <div className="input">
                            <div className="input_title">
                                Current Level
                            </div>
                            <input type="number" style={{ color: current >= 1 && current < 713 ? "black" : "red" }} placeholder="1" value={current} onChange={e => setCurrent(parseInt(e.currentTarget.value) || e.currentTarget.value)} />
                            <div style={{ marginTop: "10px" }}>Min level 1</div>
                        </div>

                        <div className="arrow">→</div>

                        <div className="input">
                            <div className="input_title">
                                Desired Level
                            </div>
                            <input type="number" style={{ color: desired > 1 && desired <= 713 ? "black" : "red" }} placeholder="1" value={desired} onChange={e => setDesired(parseInt(e.currentTarget.value) || e.currentTarget.value)} />
                            <div style={{ marginTop: "10px" }}>Max level 713</div>
                        </div>
                    </div>

                    <div className="required">
                        <div className="equals">=</div>
                        <div className="title">
                            Runes Needed
                        </div>
                        <div className="value">
                            {current > 0 && current < 713 && desired > 1 && desired <= 713 ? typeof eldenring.runesFromTo(current, desired) === "number" ? commaNumber(eldenring.runesFromTo(current, desired)) : 0 : 0}
                        </div>
                    </div>

                    {current >= 1 && desired <= 713 && current && desired && desired > current ?
                        <div className="accordion">
                            <AccordionButton id="breakdown_acc" phrase="View breakdown" alt="Hide breakdown" chevron />
                            <Accordion id="breakdown_acc">
                                {getBreakdown()}
                                <div style={{ display: "block", position: "relative", lineHeight: "50px", cursor: "pointer", width: "200px", margin: "0px auto" }} onClick={() => {
                                    document.getElementById("breakdown_acc").style.maxHeight = "0px"
                                    document.getElementById("breakdown_acc_button_text").innerHTML = "View breakdown"
                                    document.getElementById("breakdown_acc_button_chevron").style.transform = "translate(-50%, -50%) rotate(0deg)"
                                }}>Hide breakdown</div>
                            </Accordion>
                        </div>
                        : null
                    }
                </div >
            </div >
        </div>
    );
}

export default Runes;