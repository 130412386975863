import commaNumber from 'comma-number';
import React, { useEffect, useState } from 'react';

function Consumables(props) {
    const [consumables, setConsumables] = useState({
        pauper: {
            number: 0,
            value: 200,
            sum: 0
        },
        lands: {
            number: 0,
            value: 3000,
            sum: 0
        },
        golden1: {
            number: 0,
            value: 200,
            sum: 0
        },
        golden2: {
            number: 0,
            value: 400,
            sum: 0
        },
        golden3: {
            number: 0,
            value: 800,
            sum: 0
        },
        golden4: {
            number: 0,
            value: 1200,
            sum: 0
        },
        golden5: {
            number: 0,
            value: 1600,
            sum: 0
        },
        golden6: {
            number: 0,
            value: 2000,
            sum: 0
        },
        golden7: {
            number: 0,
            value: 2500,
            sum: 0
        },
        golden8: {
            number: 0,
            value: 3000,
            sum: 0
        },
        golden9: {
            number: 0,
            value: 3800,
            sum: 0
        },
        golden10: {
            number: 0,
            value: 5000,
            sum: 0
        },
        golden11: {
            number: 0,
            value: 6200,
            sum: 0
        },
        golden12: {
            number: 0,
            value: 7500,
            sum: 0
        },
        golden13: {
            number: 0,
            value: 10000,
            sum: 0
        },
        numen: {
            number: 0,
            value: 12500,
            sum: 0
        },
        hero1: {
            number: 0,
            value: 15000,
            sum: 0
        },
        hero2: {
            number: 0,
            value: 20000,
            sum: 0
        },
        hero3: {
            number: 0,
            value: 25000,
            sum: 0
        },
        hero4: {
            number: 0,
            value: 30000,
            sum: 0
        },
        hero5: {
            number: 0,
            value: 35000,
            sum: 0
        },
        lord: {
            number: 0,
            value: 50000,
            sum: 0
        },
        grafted: {
            number: 0,
            value: 20000,
            sum: 0
        },
        queen: {
            number: 0,
            value: 20000,
            sum: 0
        },
        starscourge: {
            number: 0,
            value: 40000,
            sum: 0
        },
        regal: {
            number: 0,
            value: 30000,
            sum: 0
        },
        naturalborn: {
            number: 0,
            value: 30000,
            sum: 0
        },
        hoarah: {
            number: 0,
            value: 30000,
            sum: 0
        },
        blade: {
            number: 0,
            value: 30000,
            sum: 0
        },
        blasphemous: {
            number: 0,
            value: 50000,
            sum: 0
        },
        giant: {
            number: 0,
            value: 30000,
            sum: 0
        },
        omen: {
            number: 0,
            value: 30000,
            sum: 0
        },
        dragonlord: {
            number: 0,
            value: 30000,
            sum: 0
        },
        lichdragon: {
            number: 0,
            value: 30000,
            sum: 0
        },
        elden: {
            number: 0,
            value: 50000,
            sum: 0
        },
        rot: {
            number: 0,
            value: 50000,
            sum: 0
        },
        blood: {
            number: 0,
            value: 30000,
            sum: 0
        },

        // DLC consumables
        broken: {
            number: 0,
            value: 500,
            sum: 0
        },
        shadow1: {
            number: 0,
            value: 7500,
            sum: 0
        },
        shadow2: {
            number: 0,
            value: 10000,
            sum: 0
        },
        shadow3: {
            number: 0,
            value: 12500,
            sum: 0
        },
        shadow4: {
            number: 0,
            value: 15000,
            sum: 0
        },
        shadow5: {
            number: 0,
            value: 17500,
            sum: 0
        },
        shadow6: {
            number: 0,
            value: 22500,
            sum: 0
        },
        shadow7: {
            number: 0,
            value: 30000,
            sum: 0
        },
        leda: {
            number: 0,
            value: 40000,
            sum: 0
        },
        unsung: {
            number: 0,
            value: 50000,
            sum: 0
        },
        marika: {
            number: 0,
            value: 80000,
            sum: 0
        },
        beast: {
            number: 0,
            value: 30000,
            sum: 0
        },
        rellana: {
            number: 0,
            value: 30000,
            sum: 0
        },
        gaius: {
            number: 0,
            value: 50000,
            sum: 0
        },
        scadutree: {
            number: 0,
            value: 50000,
            sum: 0
        },
        messmer: {
            number: 0,
            value: 50000,
            sum: 0
        },
        romina: {
            number: 0,
            value: 50000,
            sum: 0
        },
        putrescent: {
            number: 0,
            value: 30000,
            sum: 0
        },
        midra: {
            number: 0,
            value: 30000,
            sum: 0
        },
        metyr: {
            number: 0,
            value: 30000,
            sum: 0
        },
        consort: {
            number: 0,
            value: 50000,
            sum: 0
        }
    })

    function addRune(state, e) {
        let new_state = {
            number: parseInt(e.currentTarget.value),
            value: consumables[state].value,
            sum: consumables[state].value * e.currentTarget.value || 0
        }
        setConsumables({
            ...consumables,
            [state]: new_state
        })
    }

    function plusRune(state) {
        setConsumables({
            ...consumables,
            [state]: {
                number: consumables[state].number + 1,
                value: consumables[state].value,
                sum: consumables[state].value * (consumables[state].number + 1)
            }
        })
    }

    function minusRune(state) {
        if (consumables[state].number > 0) {
            setConsumables({
                ...consumables,
                [state]: {
                    number: consumables[state].number - 1,
                    value: consumables[state].value,
                    sum: consumables[state].value * (consumables[state].number - 1)
                }
            })
        }
    }

    function getConsumable(name, state) {
        return <div className="consumable">
            <div className="name">
                {name}
            </div>
            <div className="number_input">
                <div className="minus" onClick={() => minusRune(state)}>-</div>
                <input type="number" max={99} value={consumables[state].number} onChange={e => addRune(state, e)} />
                <div className="plus" onClick={() => plusRune(state)}>+</div>
            </div>
        </div>
    }

    function getConsumableValue() {
        let sum = 0
        Object.entries(consumables).forEach(e => {
            sum += e[1].sum
        })

        return commaNumber(sum)
    }

    return (
        <div id="consumables-calculator" className='runes_container'>
            <div className="consumables">
                <div className="title">How many consumable runes do you have?</div>

                <div className="subtitle">Elden Ring</div>

                {getConsumable("Pauper's rune", "pauper")}
                {getConsumable("Lands between rune", "lands")}
                {getConsumable("Golden rune [1]", "golden1")}
                {getConsumable("Golden rune [2]", "golden2")}
                {getConsumable("Golden rune [3]", "golden3")}
                {getConsumable("Golden rune [4]", "golden4")}
                {getConsumable("Golden rune [5]", "golden5")}
                {getConsumable("Golden rune [6]", "golden6")}
                {getConsumable("Golden rune [7]", "golden7")}
                {getConsumable("Golden rune [8]", "golden8")}
                {getConsumable("Golden rune [9]", "golden9")}
                {getConsumable("Golden rune [10]", "golden10")}
                {getConsumable("Golden rune [11]", "golden11")}
                {getConsumable("Golden rune [12]", "golden12")}
                {getConsumable("Golden rune [13]", "golden13")}
                {getConsumable("Numen's rune", "numen")}
                {getConsumable("Hero rune [1]", "hero1")}
                {getConsumable("Hero rune [2]", "hero2")}
                {getConsumable("Hero rune [3]", "hero3")}
                {getConsumable("Hero rune [4]", "hero4")}
                {getConsumable("Hero rune [5]", "hero5")}
                {getConsumable("Lord's rune", "lord")}

                {getConsumable("Remembrance of the Grafted", "grafted")}
                {getConsumable("Remembrance of the Full Moon Queen", "queen")}
                {getConsumable("Remembrance of the Starscourge", "starscourge")}
                {getConsumable("Remembrance of the Regal Ancestor", "regal")}
                {getConsumable("Remembrance of the Naturalborn", "naturalborn")}
                {getConsumable("Remembrance of the Hoarah Loux", "hoarah")}
                {getConsumable("Remembrance of the Black Blade", "blade")}
                {getConsumable("Remembrance of the Blasphemous", "blasphemous")}
                {getConsumable("Remembrance of the Fire Giant", "giant")}
                {getConsumable("Remembrance of the Omen King", "omen")}
                {getConsumable("Remembrance of the Dragonlord", "dragonlord")}
                {getConsumable("Remembrance of the Lichdragon", "lichdragon")}
                {getConsumable("Elden Remembrance", "elden")}
                {getConsumable("Remembrance of the Rot Goddess", "rot")}
                {getConsumable("Remembrance of the Blood Lord", "blood")}

                <div className="subtitle" style={{marginTop: "40px"}}>Shadow of the Erdtree</div>

                {getConsumable("Broken rune", "broken")}
                {getConsumable("Shadow realm rune [1]", "shadow1")}
                {getConsumable("Shadow realm rune [2]", "shadow2")}
                {getConsumable("Shadow realm rune [3]", "shadow3")}
                {getConsumable("Shadow realm rune [4]", "shadow4")}
                {getConsumable("Shadow realm rune [5]", "shadow5")}
                {getConsumable("Shadow realm rune [6]", "shadow6")}
                {getConsumable("Shadow realm rune [7]", "shadow7")}
                {getConsumable("Leda's rune", "leda")}
                {getConsumable("Rune of an Unsung Hero", "unsung")}
                {getConsumable("Marika's rune", "marika")}

                {getConsumable("Remembrance of the Dancing Lion", "beast")}
                {getConsumable("Remembrance of the Twin Moon Knight", "rellana")}
                {getConsumable("Remembrance of the Wild Boar Rider", "gaius")}
                {getConsumable("Remembrance of the Shadow Sunflower", "scadutree")}
                {getConsumable("Remembrance of the Impaler", "messmer")}
                {getConsumable("Remembrance of the Saint of the Bud", "romina")}
                {getConsumable("Remembrance of Putrescence", "putrescent")}
                {getConsumable("Remembrance of the Lord of Frenzied Flame", "midra")}
                {getConsumable("Remembrance of the Mother of Fingers", "metyr")}
                {getConsumable("Remembrance of a God and a Lord", "consort")}

                <div className="result">
                    = {getConsumableValue()} runes
                </div>
            </div>
        </div>
    );
}

export default Consumables;